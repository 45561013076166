$hk-color-white: #ffffff;
$hk-color-light-gray: #f2f5f7;
$hk-color-gray: #cccccc;
$hk-color-dark-gray: #787872;
$hk-color-darker-gray: #444444;
$hk-color-black: #222222;
$hk-color-dark-green: #007861;
$hk-color-green: #79c043;
$hk-color-navy: #002a40;
$hk-color-gray-stroke: #e4e5e5;
$hk-color-dark-blue: #3b5998;
$hk-color-neutral: #b4b4b4;

// typography color
$hk-color-black-title: #404041;
$hk-color-navy-title: #002a40;
$hk-color-heading-1: #808080;
$hk-color-heading-2: #bbc9d4;

// status color
$hk-color-status-green: #38a169;
$hk-color-status-yellow: #ecc12a;
$hk-color-status-red: #ed2b2a;
$hk-color-status-orange: #fa8c16;
$hk-color-status-blue: #2587fb;

// color theme
$primary-color: $hk-color-darker-gray;
$secondary-color: lighten($primary-color, 20);
