@import 'scss/mixins';
@import 'scss/variables';

.main-chat {
  &-container {
    position: relative;
    height: 100%;
  }

  &-wrapper {
    border: none;
    display: flex;
    flex-direction: column !important;
  }

  &-head {
    padding: 24px;
    border-bottom: 1px solid $hk-color-gray-stroke;

    &-button-guide {
      align-items: center;
      background-color: #e7fad9;
      border-block: 1px solid $hk-color-gray-stroke;
      border-inline: none;
      cursor: pointer;
      display: flex;
      gap: 10px;
      padding: 4px 24px;
      text-align: left;
      transition: background-color 0.2s;
      width: 100%;
      & > span {
        color: $hk-color-black-title;
        margin-bottom: 2px;
      }
      &:hover {
        background-color: darken(#e7fad9, 5);
      }
    }

    &.legal-draft {
      padding-block: 12px;
      border-bottom: none;
    }

    .user-name {
      font-size: 18px;
    }

    .create-offer {
      font-size: 16px;

      &-tag {
        font-size: 8px;
      }

      &-caption {
        font-size: 12px;
      }
    }

    .session-state {
      font-size: 14px;
    }

    .ant-tag {
      margin-inline-end: 0;
    }

    .ant-space,
    .ant-space-item {
      max-width: 100%;
    }
  }

  &-empty-state-icon {
    width: 180px;
    height: 180px;
  }

  @include mobile-breakpoint() {
    &-head {
      padding: 10px 16px;

      .user-name {
        font-size: 14px;
      }

      .session-state {
        font-size: 12px;
      }
      .create-offer {
        font-size: 12px;

        &-caption {
          font-size: 10px;
        }
      }
    }
  }
}
