.offer-card {
  &__container {
    .ant-card-head {
      border-bottom: none;
      padding: 0px 12px 0px 12px;
    }
    .ant-card-body {
      padding: 0px 12px 12px 12px;
    }

    .topic-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
    }

    .ant-divider {
      margin: 12px 0;
    }

    .detail-button {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.legal-card-skeleton {
  .ant-skeleton-button {
    height: 175px !important;
  }
}
