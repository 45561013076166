.legal-draft-guidebook {
  .ant-modal-content {
    @media only screen and (max-width: 992px) {
      padding: 12.43px;
    }
    @media only screen and (max-width: 768px) {
      padding-top: 46.86px;
    }
    padding: 42px;
  }

  .content {
    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 21.75px;
    }
    display: grid;
    grid-template-columns: minmax(0, 568px) 1fr;
    gap: 42px;

    .guide {
      &-info {
        @media only screen and (max-width: 992px) {
          padding-top: 21.75px;
        }
        @media only screen and (max-width: 768px) {
          padding-top: 0;
        }
        padding-top: 56px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          @media only screen and (max-width: 992px) {
            margin: 0 0 12.43px;
            font-size: 31px;
            line-height: 130%;
          }
          @media only screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 120%;
          }
          font-weight: 700;
          font-size: 42px;
          line-height: 50.4px;
          margin: 0 0 24px;
        }
        .subtitle {
          @media only screen and (max-width: 768px) {
            font-size: 11px;
          }
          font-size: 18px;
          margin: 0 0 16.57px;
          line-height: 150%;
          font-weight: 400;
          .highlight {
            font-weight: 500;
          }
        }
        .footer {
          display: flex;
          justify-content: space-between;
          gap: 12px;
          align-items: center;
          .pointer {
            display: flex;
            align-items: center;
            gap: 0.875rem;
            @media only screen and (max-width: 768px) {
              gap: 7.26px;
            }

            &-bullet {
              @media only screen and (max-width: 768px) {
                height: 5px;
                width: 5px;
              }
              height: 9.82px;
              width: 9.82px;
              padding: 0;
              background-color: #aaaaaa;
              border-radius: 9999px;
              border: none;
              transition-property: height, width, color, opacity;
              transition-duration: 200ms;

              &.active {
                @media only screen and (max-width: 768px) {
                  height: 8px;
                  width: 8px;
                }
                background-color: #4e944f;
                height: 15.43px;
                width: 15.43px;
              }
            }
          }

          .buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
            .hidden {
              opacity: 0;
              pointer-events: none;
            }
          }
        }
      }
      &-image {
        display: block;
        width: 100%;
      }
    }
  }
}
