@import "../variables";
@import "../helpers/mixins";

.#{$prefix}-main-container {
  position:relative;
  @include flex-row;
  height: 100%;
  overflow: auto;
  border: $main-container-border;
  box-sizing: border-box;
  color: $main-container-color;
  background-color: $main-container-bg-color;
  font-size: $main-container-font-size;
  
  & > .#{$prefix}-conversation-list {
    order: 0;
    height: 100%;
    flex-basis: $main-container-conversation-list-flex-basis;
    border-top: $main-container-conversation-list-border-top;
    border-right: $main-container-conversation-list-border-right;
    border-bottom: $main-container-conversation-list-border-bottom;
    border-left: $main-container-conversation-list-border-left;
    box-shadow: 2px 0 5px -2px rgba(0,0,0,0.38);
    /* Hmm without this, box shadow is cut at bottom */
    z-index:2;
  }

  & > .#{$prefix}-sidebar.#{$prefix}-sidebar--left {
    order:0;
    height:100%;
    max-width:$main-container-sidebar-left-max-width;
    flex-basis: 35%;
    border-right: $main-container-conversation-list-border-right;
    //box-shadow: 2px 0 5px -2px rgba(0,0,0,0.38);
    /* Hmm without this, box shadow is cut at bottom */
    z-index:2;
  }

  & > .#{$prefix}-sidebar.#{$prefix}-sidebar--right {
    flex-basis: 25%;
    min-width: $main-container-sidebar-right-min-width;
    max-width: $main-container-sidebar-right-max-width;

    border-top: $main-container-sidebar-right-border-top;
    border-right: $main-container-sidebar-right-border-right;
    border-bottom: $main-container-sidebar-right-border-bottom;
    border-left: $main-container-sidebar-right-border-left;
    
  }
  
  & > .#{$prefix}-sidebar.#{$prefix}-sidebar--left .#{$prefix}-search {
    margin:0.5em;
  }
  
  & .#{$prefix}-chat-container {
    order:1;
    z-index:1;
    flex-grow: 1;
    flex-basis: 65%;
    border-right: $main-container-chat-container-border-right;
  }
  
  & .#{$prefix}-sidebar {
    order:2;
    height:100%;
    //box-shadow: -2px 0px 5px -2px rgba(0,0,0,0.38);
    z-index:3;
  }

  // Clear expansion panel border in sidebar
  & .#{$prefix}-sidebar .#{$prefix}-expansion-panel {
    border-left:0;
    border-top:0;
    border-right:0;
  }

  // 3 because of scrollbar divs
  & .#{$prefix}-sidebar .#{$prefix}-expansion-panel:nth-last-child(3) {
    border-bottom: 0;
  }
  
  & .#{$prefix}-conversation-header {
    z-index: 1;
  }

  
  /////////////////////////////////////////////////////////////
  /// Responsive
  &--responsive {

    .#{$prefix}-chat-container {
      
      .#{$prefix}-conversation-header {
        .#{$prefix}-conversation-header__back {
          display: none;
        }
  
        .#{$prefix}-conversation-header__actions {
          .#{$prefix}-button--info {
            display: none;
          }
        }
        
      }
    }
    
    @media( max-width: map-get($grid-breakpoints, md) ) {
      
      min-width: calc( 1.6em + 40px + #{$chat-container-min-width} );  // Horizontal padding from conversation-list plus avatar width plus ChatContainer min-width
      
      .#{$prefix}-search {
        display:none;
      }
  
      > .#{$prefix}-sidebar.#{$prefix}-sidebar--left {
        flex-basis: calc(1.6em + 40px); // Horizontal padding from conversation-list plus avatar width
        min-width: calc(1.6em + 40px);
      }
  
      >.#{$prefix}-sidebar.#{$prefix}-sidebar--right {
        display: none;
      }
      
      > .#{$prefix}-conversation-list { // Only if conversation-list is direct child of main-container because main-container and conversation-list have different flex-direction
        flex-basis: calc(1.6em + 40px); // Horizontal padding from conversation-list plus avatar width
      }
  
      .#{$prefix}-conversation-list,
      .#{$prefix}-sidebar .#{$prefix}-conversation-list {
  
        .#{$prefix}-conversation {
          > .#{$prefix}-avatar {
            margin-right:0; // Reset right margin
          }
  
          &__content {
            display:none;
          }
  
          &__operations {
            display:none;
          }
          
          &__last-activity-time {
            display: none;
          }
          &__unread-dot {
            position:absolute;
            top:0.3em;
            right:0.3em;
            margin-right:0;
          }
        }
      }

      .#{$prefix}-conversation-header {

        .#{$prefix}-conversation-header__actions {
          .#{$prefix}-button--info {
            display: flex;
          }
        }
        
      }
      
    }
  
    @media( max-width: map-get($grid-breakpoints, sm) ) {

      min-width: auto;
      
      > .#{$prefix}-sidebar.#{$prefix}-sidebar--left {
        display:none;
      }


      .#{$prefix}-chat-container {
        // Show back arrow
        .#{$prefix}-conversation-header {
          .#{$prefix}-conversation-header__back {
            display: flex;
          }
  
          .#{$prefix}-conversation-header__info {
            
          }
          
        }
      }
      
      
      
    }
  }
  
/////////////////////////////////////////////////////////////
  
}
