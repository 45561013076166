.hk-message-document {
  position: relative;

  .backdrop {
    background: black;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 0.15s ease-in-out;
  }

  .download-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    opacity: 0;
  }

  .file-icon {
    font-size: 120px;
    margin: 1rem 1rem 0.25rem;
  }

  .file-name {
    width: 150px;
    text-align: center;
  }

  &:hover {
    .backdrop {
      opacity: 0.2;
    }

    .download-button {
      z-index: 2;
      opacity: 1;
    }
  }
}
