.forum-collapse {
  border: none;
  background: transparent;
  border-radius: 0px;
  .ant-collapse-item {
    border-radius: 0px !important;
    .ant-collapse-header {
      padding-left: 0px;
      border-radius: 0px !important;
      align-items: center;
    }
  }
}
