.idle-modal {
  .ant-modal-body {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .ant-space-item {
      text-align: center;

      & > .ant-typography {
        font-weight: 400;
        font-size: 18px;
      }
    }
  }
}
