@import 'scss/mixins';
@import 'scss/variables';

.legal-draft-modal {
  &-title {
    font-size: 32px !important;
  }

  &__upload {
    .uploader {
      &-text {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
      }
    }

    .alert {
      &-wrapper {
        display: flex;
        padding: 2px 4px;
        align-items: flex-start;
        background-color: #ed2b2a;
        margin: 12px 0;
        border-radius: 0;
        border: none;
      }

      &-text {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        color: white;
      }
    }

    .file-list {
      .name {
        color: #11263f;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  &__forum-discussion {
    .ant-modal-content {
      height: 100%;
    }
    &-body {
      .skeleton {
        &-forum-card {
          height: 220px !important;
        }
        &-document-detail {
          height: 480px !important;
        }
        &-revision {
          height: 300px !important;
        }
      }

      .card-wrapper {
        position: sticky;
      }
      .revision-wrapper {
        overflow-y: auto;
        height: 988px;
        padding-bottom: 40px;
      }
    }

    .forum-payment-card {
      .ant-card-body {
        padding: 12px;

        .ant-divider {
          margin: 12px 0;
        }
      }
    }
  }
}

@include mobile-breakpoint() {
  .legal-draft-modal {
    &-title {
      font-size: 23px !important;
    }
    &__forum-discussion {
      &-body {
        .card-wrapper {
          position: relative;
        }
        .revision-wrapper {
          height: 100%;
        }
      }
    }
  }
}
