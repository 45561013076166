@import 'scss/variables/index';

html {
  font-family:
    'Work Sans',
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 16px;

  body {
    margin: 0;
  }
}

a {
  color: inherit;

  &:hover {
    color: $primary-color;
  }
}

::-webkit-scrollbar-button {
  display: none;
}
