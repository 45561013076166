.h {
  &-full {
    height: 100% !important;
  }
  &-auto {
    height: auto !important;
  }
}

.w {
  &-full {
    width: 100% !important;
  }
}
