@import 'scss/mixins';
@import 'scss/variables';

.ant-result {
  @include mobile-breakpoint() {
    & &-title {
      font-size: 20px;
    }

    & &-subtitle {
      font-size: 12px;
    }
  }
}

.ant-pagination {
  @include mobile-breakpoint() {
    & &-options {
      display: inline-block;
    }
  }
}

.ant-steps {
  &-item-finish {
    .ant-steps-item-title {
      color: $hk-color-dark-green !important;
    }
  }
}

.ant-typography {
  @include mobile-breakpoint() {
    font-size: 12px;
  }
}

.anticon-large {
  font-size: 100px;
}
