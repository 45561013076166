@import 'scss/variables/index';

.hk-border-radius {
  &-sm {
    border-radius: 8px;
  }
  &-md {
    border-radius: 12px;
  }
  &-lg {
    border-radius: 16px;
  }
  &-full {
    border-radius: 50%;
  }
}
