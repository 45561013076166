@import 'scss/variables/index';

.otp-modal {
  padding: 24px 42px;
  .otp {
    &-input {
      width: 82px !important;
      height: 47px;
      border: none;
      border-bottom: $hk-color-gray-stroke 2px solid;
      color: $hk-color-black-title;
      font-size: 32px;
      font-weight: 600;
    }
  }
}
