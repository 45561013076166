@import 'scss/variables/index';

.media-uploader {
  width: 100%;
  height: 44px;
  .ant {
    &-btn-primary {
      background-color: $hk-color-dark-green;
    }

    @media screen and (width < $desktop) {
      &-typography-ellipsis {
        max-width: 200px;
      }
    }

    @media screen and (width < $big-tablet) {
      &-typography-ellipsis {
        max-width: 90px;
      }
    }
  }
  &.input-uploader {
    border: 1px solid $hk-color-gray;
    border-radius: 8px;
    display: block;
    padding: 10px 11px;
    width: 100%;

    .ant-upload {
      width: 100%;
    }

    .placeholder {
      margin: 0;
      color: lightgray;
    }
  }
}
