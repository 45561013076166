@import 'scss/mixins';

.faq-modal {
  .ant-modal-content {
    padding: 24px;
  }
  .faq-alert {
    background-color: #c0434314;
    padding: 8px 6px;
    border: none;
    border-radius: 6px;
    span {
      align-self: flex-start;
      padding-top: 6px;
    }
  }
  .bank-label-info {
    background-color: #c0434314;
    padding: 2px;
    border-radius: 4px;
    font-size: 11px;

    @include mobile-breakpoint() {
      font-size: 10px;
    }
  }

  .confirmation-list {
    margin-bottom: 12px;
  }

  .total-alert {
    margin-top: 12px;
    margin-bottom: 24px;
    background-color: #e7fad9;
    padding: 12px 8px;
    border: none;
    border-radius: 4px;
  }
}
