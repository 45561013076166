@import 'scss/mixins';
@import 'scss/variables';

.page-footer {
  padding-top: 1.5rem;
  background: $hk-color-white;
  border-top: 1px solid $hk-color-gray;

  .link {
    color: $hk-color-navy;

    &:hover,
    &:focus {
      color: $hk-color-dark-green;
    }
  }

  .right-section {
    text-align: right;
  }

  @include mobile-breakpoint() {
    padding: 24px 20px;

    .right-section {
      text-align: left;
    }
  }
}
