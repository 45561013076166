@import 'scss/variables';
@import 'scss/mixins';

$common-border: 1px solid $hk-color-gray;

.hk-header {
  border-bottom: $common-border;
  height: 100px;

  .avatar-dropdown {
    cursor: pointer;
  }

  .status-tag {
    background-color: $hk-color-white;
    padding: $spacing-xs $spacing-lg;
  }

  .notification-popover {
    cursor: pointer;
    border: 1px solid $hk-color-gray;
    border-radius: $spacing-2xl;
    height: 50px;
    width: 40px;
    padding-left: 13px;

    .ant {
      &-badge {
        margin-top: 13px;
      }
    }
  }

  .status-select {
    width: 110px;

    &.ant-select-disabled {
      .ant-select-selector {
        background: white;
      }
    }
  }

  &.notification-popover-overlay {
    top: 117px !important;
    .ant-popover-content,
    .ant-popover-inner {
      height: 600px;
      width: 400px;
    }

    .ant-popover-inner {
      padding: 32px;
      overflow-y: scroll;
    }
  }

  @include mobile-breakpoint() {
    padding-inline: 20px;
    height: 64px;
    z-index: $z-index-drawer;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
}
