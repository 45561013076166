@import 'scss/variables';

.hk-pagination {
  .ant-pagination-item-active {
    a {
      color: white !important;
    }
  }
  .ant-pagination-item {
    a {
      color: $hk-color-dark-green;
      font-weight: 600;
    }
  }
}
