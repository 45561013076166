@import 'scss/mixins';

.upcoming-tag {
  border-radius: 1000px;

  @include mobile-breakpoint() {
    font-size: 8px;
    line-height: 14px;
  }
}
