.confirmation-modal {
  .ant-modal-title {
    font-size: 20px;
    font-weight: 500;
  }
}

.btn-middle {
  height: 32px;
  line-height: 1rem;
  padding: 6px 12px;
}
