@import 'scss/variables';
@import 'scss/mixins';

.hk-cms-sidebar {
  background: $hk-color-white !important;
  border-right: 1px solid $hk-color-gray;
  padding: 0 $spacing-md !important;

  .ant-menu {
    background: inherit;
    border-right: none !important;
    font-size: $hk-font-size-sm;

    &-submenu-active {
      .ant-menu-item-icon {
        filter: brightness(100);
      }
    }

    &-item-divider {
      margin: 12px 0px;
    }

    &-item {
      &.beta-feature,
      &.new-feature {
        height: 60px;
        .ant-typography {
          &.beta,
          &.new {
            padding: 1px 5px;
            border-radius: 12px;
            margin: 0px;
            background-color: green;
            width: fit-content;
            color: white;
            font-size: 11px;
          }
          &.new {
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translate3d(0, -50%, 0);
          }
        }
      }
    }

    &-item-active {
      & > .ant-menu-item-icon {
        filter: brightness(100);
      }

      &.beta-feature,
      &.new-feature {
        .ant-typography {
          color: white;
          transition: color 0.3s;

          &.beta,
          &.new {
            padding: 1px 5px;
            border-radius: 12px;
            margin: 0px;
            background-color: white;
            width: fit-content;
            color: green;
            font-size: 11px;
          }
        }
      }
      &.beta-feature {
        background: linear-gradient(
          221deg,
          #79c043 -1.56%,
          #007861 51.71%,
          #002a40 93.05%
        );
      }
      &.new-feature {
        background: linear-gradient(
          213.82deg,
          #002a40 -0.48%,
          #007861 38.51%,
          #79c043 128.56%
        );
      }
    }

    &-item-selected {
      & > .ant-menu-item-icon {
        filter: brightness(100);
      }

      &.beta-feature,
      &.new-feature {
        .ant-typography {
          color: white;
          transition: color 0.3s;

          &.beta,
          &.new {
            padding: 1px 5px;
            border-radius: 12px;
            margin: 0px;
            background-color: white;
            width: fit-content;
            color: green;
            font-size: 11px;
          }
        }
      }
      &.beta-feature {
        background: linear-gradient(
          221deg,
          #79c043 -1.56%,
          #007861 51.71%,
          #002a40 93.05%
        );
      }
      &.new-feature {
        background: linear-gradient(
          213.82deg,
          #002a40 -0.48%,
          #007861 38.51%,
          #79c043 128.56%
        );
      }
    }

    &-submenu-selected {
      .ant-menu-submenu-title {
        color: $hk-color-dark-green;
      }
    }

    .anticon {
      font-size: $hk-font-size-md;
    }
  }

  .user-badge {
    .ant-typography {
      font-size: $hk-font-size-md;
      color: $hk-color-green;
    }
  }

  &-backdrop {
    background: $hk-color-black;
    opacity: 0.2;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(100%);
    display: none;
    z-index: $z-index-drawer-backdrop;
  }

  @include mobile-breakpoint() {
    .ant-layout &.ant-layout-sider {
      top: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      z-index: $z-index-drawer;
    }

    & {
      transform: translateX(100%);
    }

    &-backdrop {
      display: block;
    }

    &-backdrop--open {
      transform: translateX(0);
    }

    &--open {
      transform: translateX(0);
    }
  }
}
