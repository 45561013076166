@import 'scss/mixins';

.desktop {
  display: block !important;

  @include mobile-breakpoint() {
    display: none !important;
  }
}

.mobile {
  display: none !important;

  @include mobile-breakpoint() {
    display: block !important;
  }
}

.block {
  display: block;
}

.hidden {
  display: none;
}

.flex {
  display: flex;

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }

  &.end {
    justify-content: end;
  }

  &.items-center {
    align-items: center;
  }

  &.gap-none {
    gap: 0px;
  }
  &.gap-2xs {
    gap: $spacing-2xs;
  }
  &.gap-xs {
    gap: $spacing-xs;
  }
  &.gap-sm {
    gap: $spacing-sm;
  }
  &.gap-md {
    gap: $spacing-md;
  }
  &.gap-lg {
    gap: $spacing-lg;
  }
  &.gap-xl {
    gap: $spacing-xl;
  }
  &.gap-2xl {
    gap: $spacing-2xl;
  }
  &.gap-3xl {
    gap: $spacing-3xl;
  }
}
