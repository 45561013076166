.hk-case-modal-content {
  .description {
    max-height: 250px;
    overflow-y: auto;

    .description__expand {
      margin-top: 0.75rem;
      margin-left: 0;
      display: block;
    }
  }
}
