@import 'scss/mixins';
@import 'scss/variables';

.slide-container {
  .slide-header {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;

    & > .text-title {
      font-size: 16px;
      font-weight: 400;
      width: 350px;
      text-align: center;
    }
  }

  .slick-dots {
    position: relative !important;
    bottom: 40px !important;
  }

  .slick-dots li button:before {
    background-color: #4e944f;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    text-indent: -9999px;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slide-wrapper {
    .slide-text {
      &-title {
        margin-top: $spacing-xl;
        font-size: 28px;
        font-weight: 600;
      }
      &-caption {
        font-size: 16px;
        font-weight: 400;
      }
    }
    img {
      width: 300px;
      height: 240px;
      object-fit: contain;
      margin: auto;
    }
  }
}

@include mobile-breakpoint() {
  .slide-container {
    padding-top: 16px;

    .slide-header {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      & > .text-title {
        font-size: 12px;
        font-weight: 400;
        width: 350px;
        text-align: center;
      }
    }

    .slick-list {
      height: 250px;
    }

    .slick-dots {
      bottom: 18px !important;
    }

    .slick-dots li button:before {
      height: 8px;
      width: 8px;
    }

    .slide-wrapper {
      .slide-text {
        &-title {
          margin-top: $spacing-md;
          font-size: 14px;
          font-weight: 600;
        }
        &-caption {
          font-size: 12px;
          font-weight: 400;
        }
      }
      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        margin: auto;
      }
    }
  }
}
