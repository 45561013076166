.cs-main-container {
  border: none !important;
  overflow-x: hidden !important;
}

.cs-conversation.cs-conversation--active {
  background-color: #f9f9f9 !important;
}

.cs-chat-container {
  .cs-conversation-header {
    background-color: white;

    .cs-conversation-header__user-name {
      background-color: white;
    }

    .cs-conversation-header__info {
      background-color: white;
    }
  }

  .cs-message-list {
    height: 55vh;
  }

  .cs-message-input {
    margin-top: 0 !important;
  }

  .message-incoming {
    .cs-message__content-wrapper {
      .cs-message__content {
        border-radius: 10px 10px 10px 0 !important;
        background-color: #f1f1f1;
        font-size: 16px;
        font-weight: 400;
        color: $hk-color-black-title;
      }
    }
  }
  .message-outgoing {
    .cs-message__content-wrapper {
      .cs-message__content {
        border-radius: 10px 10px 0px 10px !important;
        background-color: #e7fad9;
        font-size: 16px;
        font-weight: 400;
        color: $hk-color-black-title;
      }
    }
  }
  .cs-message__footer .cs-message__sent-time {
    color: $hk-color-gray;
  }
  .last-message-info {
    background-color: #e7fad9;
    padding: 0.25rem;
    border-radius: 1rem;
  }

  @include mobile-breakpoint() {
    max-height: 55vh;
  }
}

.cs-message-input {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
  border: 1px solid $hk-color-gray-stroke;
  padding: 24px !important;
  .cs-message-input__content-editor-wrapper {
    background-color: #f9f9f9;
    border: 1px solid $hk-color-gray-stroke;
    border-radius: 6px;

    .cs-message-input__content-editor {
      background-color: #f9f9f9;
    }
  }

  .cs-message-input__tools {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $hk-color-dark-green;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    .cs-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .cs-button--send {
      color: white;
      width: 45px;
      height: 45px;
    }
  }

  .cs-button:disabled {
    border-radius: 50%;
    opacity: 1;
    cursor: not-allowed;
    background: $hk-color-dark-gray;
  }

  .cs-button--attachment {
    color: white;
  }

  @include mobile-breakpoint() {
    padding: 24px 16px 0 !important;

    &__content-editor-wrapper {
      padding: 8px 12px !important;
    }
  }
}

.cs-message-separator::before,
.cs-message-separator::after {
  content: none !important;
}
