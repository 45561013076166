@import 'scss/mixins';
@import 'scss/variables';

.issue-wrapper {
  border-radius: 6px;
  border: 1px solid $hk-color-gray-stroke;
  margin: 24px;
  padding: 12px 14px;
  .detail {
    cursor: pointer;
  }

  @include mobile-breakpoint() {
    margin: 10px 16px 16px;
    padding: 8px 10px;
  }
}

.client-issue-modal {
  .ant-modal-content {
    padding: 24px 42px;
  }

  @include mobile-breakpoint() {
    .ant-modal-content {
      padding: 20px 24px;
    }
  }
}
