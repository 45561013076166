@import 'scss/variables/index';

.hk-color-dark-green {
  color: #007861;
}
.hk-color-light-green {
  color: #2c8961;
}
.hk-color-black-title {
  color: #404041 !important;
}
.hk-color-heading-1 {
  color: #808080;
}
.hk-color-heading-2 {
  color: #bbc9d4;
}
.hk-color-dark-grey {
  color: #787872;
}
.hk-color-navy {
  color: #002a40;
}
.hk-color-white {
  color: #ffffff;
}
.hk-color-status-yellow {
  color: $hk-color-status-yellow;
}
.hk-color-status-red {
  color: $hk-color-status-red;
}
.hk-color-green {
  color: $hk-color-green;
}
.hk-color-orange {
  color: $hk-color-status-orange;
}

.hk-color-gold {
  color: gold;
}
