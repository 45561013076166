@import 'scss/mixins';

.consultation-rating-modal {
  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & .ant-form-item-label {
    padding: 0px;
  }
}
