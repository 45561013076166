@import 'scss/variables/index';

.ant-btn.hk-button {
  &.btn-link-navy {
    color: $hk-color-navy;

    &:hover {
      color: $hk-color-navy;
    }
  }

  &.btn-primary-dark-green {
    background-color: $hk-color-dark-green;
    &:disabled {
      background-color: $hk-color-gray;
    }
  }

  &.btn-primary-navy {
    background-color: $hk-color-navy;
  }

  &.btn-ghost-icon-dark-green {
    background-color: rgba(0, 120, 97, 0.15);
  }

  &.btn-ghost-icon-red {
    background-color: rgba(237, 43, 42, 0.15);
  }

  &.btn-text-dark-green {
    color: $hk-color-dark-green;

    &:hover {
      color: $hk-color-dark-green;
    }
  }
}
