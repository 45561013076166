@import 'scss/mixins';
@import 'scss/variables';

.hk-cms {
  min-height: 100vh;

  &-layout {
    min-height: 100vh;

    .ant-layout-content {
      padding: $spacing-md $spacing-lg;
      background-color: $hk-color-white;
    }
  }

  @include mobile-breakpoint() {
    min-height: 50vh;

    &-layout {
      min-height: 50vh;
    }
  }
}

.hk-layout {
  &-basic-no-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $hk-color-navy;
    height: 100%;
    &-row {
      background-color: $hk-color-navy;
    }
  }
}

@include mobile-breakpoint() {
  .hk-cms {
    &-layout {
      .ant-layout-content {
        padding: 20px;
        background-color: $hk-color-white;
      }
    }
  }
}
