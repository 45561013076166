@import 'scss/mixins';
@import 'scss/variables';

.user-info {
  border-bottom: 1px $hk-color-light-gray solid;
  padding: $spacing-lg $spacing-md;

  @include mobile-breakpoint() {
    padding: $spacing-sm 0 $spacing-lg;

    .amount-label {
      font-size: 10px;
    }
  }
}
